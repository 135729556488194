import { productsCaruselSlider } from './main.js';
import { setSliderWidth1, enableSwipe } from './slider.js';

//
document.addEventListener("DOMContentLoaded", function () {
    if(unique_class_2){
        setSliderWidth1(`.first-slider${unique_class_2}`);
        productsCaruselSlider(`.hotdeal-slider${unique_class_2}`, 500000000000, 1);
        enableSwipe(`.first-slider${unique_class_2}`); // Добавляем поддержку свайпа
    }
    setSliderWidth1(`.first-slider${unique_class_1}`);
    productsCaruselSlider(`.hotdeal-slider${unique_class_1}`, 500000000000, 1);
    enableSwipe(`.first-slider${unique_class_1}`); // Добавляем поддержку свайпа
});
//
window.addEventListener('resize', () => {
    setSliderWidth1(`.first-slider${unique_class_1}`);
    if(unique_class_2){
        setSliderWidth1(`.first-slider${unique_class_2}`);
    }
    productsCaruselSlider('.similar-slider', 500000, 1);
});